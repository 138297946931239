import React from "react"
import PropTypes from "prop-types"

const Logo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 407.538 70'} className={'w-full'}>
      <defs>
        <clipPath id="a">
          <path data-name="Rectangle 8" fill="none" d="M0 0h407.538v70H0z" />
        </clipPath>
      </defs>
      <g data-name="Calque 1">
        <g data-name="Groupe 3" clipPath="url(#a)">
          <path
            data-name="Tracé 16"
            d="M212.496-221.979l48.631 50.08-268.07 276.06-48.63-50.081z"
            fill="#ff8a00"
          />
          <path
            data-name="Tracé 17"
            d="M260.537-172.506l48.631 50.08-268.07 276.06-48.63-50.081z"
            fill="#da1b60"
          />
          <path
            data-name="Tracé 18"
            d="M404.073-24.693l48.631 50.08-268.07 276.06-48.63-50.081z"
            fill="#4caf50"
          />
          <path
            data-name="Tracé 19"
            d="M357.403-72.755l48.631 50.08-268.07 276.06-48.63-50.081z"
            fill="#03a9f4"
          />
          <path
            data-name="Tracé 20"
            d="M309.166-122.429l48.631 50.08-268.07 276.06-48.63-50.081z"
            fill="#f44336"
          />
        </g>
        <path
          data-name="Tracé 5"
          d="M11.044 45.947l12-3.609s.432 5.076 4.679 5.076c1.837 0 3.138-1.029 3.138-2.7 0-1.918-1.418-2.829-4.186-3.727l-3.815-1.208c-7.263-2.314-11.265-7.259-11.265-13.815 0-8.995 6.093-14.7 15.818-14.7 14.033 0 16.617 11.952 16.617 11.952l-12 4.369s-.493-4.433-4.372-4.433c-1.6 0-2.645.9-2.645 2.311 0 1.672 1.531 2.557 4.74 3.663l4.308 1.477c6.831 2.314 10.769 7.2 10.769 13.687 0 9.251-6.583 15.1-16.862 15.1-14.394-.015-16.924-13.443-16.924-13.443z"
          fill="#fff"
        />
        <path
          data-name="Tracé 6"
          d="M77.267 45.961v12.676H44.894V11.99h32.128v12.612H58.559v4.763h17.223v11.581H58.559v5.016z"
          fill="#fff"
        />
        <path
          data-name="Tracé 7"
          d="M77.007 45.947l12.015-3.609s.432 5.076 4.679 5.076c1.837 0 3.138-1.029 3.138-2.7 0-1.918-1.415-2.829-4.186-3.727l-3.815-1.208c-7.263-2.314-11.265-7.259-11.265-13.815 0-8.995 6.093-14.7 15.818-14.7 14.033 0 16.617 11.952 16.617 11.952l-12.001 4.366s-.493-4.433-4.369-4.433c-1.6 0-2.649.9-2.649 2.311 0 1.672 1.531 2.557 4.74 3.663l4.308 1.477c6.831 2.314 10.772 7.2 10.772 13.687 0 9.251-6.586 15.1-16.865 15.1-14.399-.012-16.937-13.44-16.937-13.44z"
          fill="#fff"
        />
        <path
          data-name="Tracé 8"
          d="M125.948 11.99h11.448l17.912 22.778V11.99h13.417v46.647h-11.451l-17.909-22.454v22.454h-13.417z"
          fill="#fff"
        />
        <path
          data-name="Tracé 9"
          d="M168.907 35.283c0-13.815 10.214-24.031 24-24.031 14.033 0 24 9.832 24 24.031 0 14.007-10.1 24.092-24 24.092-13.786 0-24-9.893-24-24.092zm34.094 0c0-6.684-3.876-10.667-10.1-10.667-6.032 0-10.1 4.178-10.1 10.667 0 6.617 4 10.6 10.1 10.6s10.1-4.181 10.1-10.6z"
          fill="#fff"
        />
        <path
          data-name="Tracé 10"
          d="M232.171 51.631h-15.138l-2.143 7.013h-14.844l16.56-46.647h16.12l16.617 46.647h-15zm-3.693-12.359l-3.322-10.883-.566-2.686-.554 2.766-3.261 10.81z"
          fill="#fff"
        />
        <path
          data-name="Tracé 11"
          d="M251.128 11.99h13.907v46.647h-13.907z"
          fill="#fff"
        />
        <path
          data-name="Tracé 12"
          d="M299.311 45.254v13.383h-31.26V11.99h13.846v33.264z"
          fill="#fff"
        />
        <path
          data-name="Tracé 13"
          d="M330.642 45.254v13.383h-31.265V11.99h13.85v33.264z"
          fill="#fff"
        />
        <path
          data-name="Tracé 14"
          d="M364.369 45.961v12.676h-32.374V11.99h32.125v12.612h-18.463v4.763h17.232v11.581h-17.232v5.016z"
          fill="#fff"
        />
        <path
          data-name="Tracé 15"
          d="M364.121 45.947l12-3.609s.429 5.076 4.676 5.076c1.837 0 3.141-1.029 3.141-2.7 0-1.918-1.418-2.829-4.186-3.727l-3.818-1.208c-7.26-2.314-11.262-7.259-11.262-13.815 0-8.995 6.093-14.7 15.818-14.7 14.033 0 16.617 11.952 16.617 11.952l-12 4.369s-.49-4.433-4.369-4.433c-1.6 0-2.646.9-2.646 2.311 0 1.672 1.531 2.557 4.74 3.663l4.308 1.477c6.831 2.314 10.769 7.2 10.769 13.687 0 9.251-6.583 15.1-16.862 15.1-14.403-.015-16.926-13.443-16.926-13.443z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

Logo.propTypes = {}

export default Logo
