import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { getChapterExcerpt } from "../../utils/get-excerpt";

const ChapterItem = ({ course, chapter }) => {
  return (
    <div className="w-full lg:flex lg:max-w-full">
      <div className="flex flex-1 flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2 leading-normal shadow-md transition duration-200 ease-in-out hover:shadow-lg">
        <div className="">
          <div className="text-xl font-bold text-gray-900">{chapter.title}</div>
        </div>
      </div>
    </div>
  )
}

const ChapterList = ({ course }) => {
  const { chapters } = course
  const sortedChapter = chapters.sort((a, b) => a.title.localeCompare(b.title))
  //console.log(sortedChapter)

  return (
    <nav className="mt-16 sm:mt-20">
      <div className="md:mx-auto md:max-w-3xl md:border-l md:border-zinc-100 md:pl-6">
        <div className="flex  flex-col space-y-16">
          {sortedChapter.map(chapter => {
            const excerpt = getChapterExcerpt(chapter)
            return (
              <>
                <article className="md:grid md:grid-cols-4 md:items-baseline">
                  <div className="group relative flex flex-col items-start break-words md:col-span-3">
                    <h2 className="text-base font-semibold tracking-tight text-zinc-800">
                      <div className="absolute -inset-y-6 -inset-x-4 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl"></div>
                      <Link
                        to={`/cours/${course.level.slug}/${course.slug}/${chapter.slug}`}
                      >
                        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                        <span className="relative z-10">{chapter.title}</span>
                      </Link>
                    </h2>
                    <time
                      className="relative z-10 order-first mb-3 flex items-center pl-3.5 text-sm text-zinc-400 md:hidden"
                      dateTime={chapter.time}
                    >
                      <span
                        className="absolute inset-y-0 left-0 flex items-center"
                        aria-hidden="true"
                      >
                        <span className="h-4 w-0.5 rounded-full bg-zinc-200"></span>
                      </span>
                      {chapter.updatedAt}
                    </time>
                    {excerpt && (
                      <div className="relative z-10 mt-2 whitespace-pre-line text-sm text-zinc-600" dangerouslySetInnerHTML={{__html: excerpt}} />
                    )}
                    <div
                      aria-hidden="true"
                      className="relative z-10 mt-4 flex items-center text-sm font-medium text-teal-500"
                    >
                      Lire le chapitre
                      <svg
                        viewBox="0 0 16 16"
                        fill="none"
                        aria-hidden="true"
                        className="ml-1 h-4 w-4 stroke-current"
                      >
                        <path
                          d="M6.75 5.75 9.25 8l-2.5 2.25"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <time
                    className="relative z-10 order-first mt-1 mb-3 flex hidden items-center text-sm text-zinc-400 md:block"
                    dateTime={chapter.time}
                  >
                    {chapter.updatedAt} <br />
                    <span className="text-gray-500">Dernière mise à jour</span>
                  </time>
                </article>
              </>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

ChapterList.propTypes = {}

export default ChapterList
