import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from "./logo"

const Navigation = (props, { location }) => {
  return (
    <>
    <div className={"fixed w-full z-30 top-0 text-white bg-white shadow"}>
      <div className="w-full container mx-auto flex flex-row items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center w-1/2">
          <Link
            to={"/"}
            className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
            href="#"
          >
            <Logo />
          </Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 bg-white"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <div
          className="w-full lg:flex-grow flex items-center md:mt-2 lg:mt-0 bg-white lg:bg-transparent text-black md:p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset flex justify-end flex-1 items-center text-sm md:text-base">
            <li className="lg:mr-3">
              <Link
                className="inline-block py-2 px-4 text-black font-bold no-underline"
                to={'/cours'}
              >
                Cours
              </Link>
            </li>
            <li className="md:mr-3">
              <Link
                className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                to="/ressources"
              >
                Ressources
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={'mt-20'} />
    </>
  )
}

Navigation.propTypes = {}

export default Navigation
