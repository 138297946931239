import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import ChapterList from "../components/course/chapter-list"

export default function CoursePage({ data }) {
  const course = data.strapiCourse
  const fluid = data.strapiCourse.featured_media.childImageSharp.fluid

  //console.log(course)
  return (
    <Layout>
      <div className="px-5">
        <div className="mt-16 md:mx-auto md:w-7/12">
          <div className="mb-10 aspect-video w-full overflow-hidden bg-gray-400">
            <Img
              fluid={fluid}
              className="h-full w-full"
              imgStyle={{ width: "100%" }}
            />
          </div>
          <p className="flex items-center text-sm text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-5 w-5 fill-current text-gray-500"
              viewBox={"0 0 791.803 791.803"}
            >
              <path d="M395.904 475.016l-236.203-72.7v121.42c0 56.133 105.787 101.644 236.274 101.644 130.48 0 236.285-45.511 236.285-101.644 0-.442-.145-.886-.154-1.318V402.316l-236.202 72.7z" />
              <path d="M0 318.734l84.383 30.185 7.191-15.401 31-2.635 4.42 4.599-26.601 6.308-3.877 11.475c-.007 0-60.087 125.604-51.263 187.05 0 0 37.502 22.37 74.988 0l9.962-168v-13.984l55.8-12.588-3.941 9.705-41.604 13.529 19.242 6.874 236.203 72.7 236.201-72.7 159.699-57.116-395.9-152.313L0 318.734z" />
            </svg>
            {course.level.title}
          </p>
          <h1 className={"mb-5 px-5 font-bold md:px-0"}>{course.title}</h1>
          <h2 className={"px-5 text-lg font-semibold text-gray-900 md:px-0"}>
            Table des matières
          </h2>
          <hr />
        </div>
        <ChapterList course={course} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiCourse(slug: { eq: $slug }) {
      slug
      title
      featured_media {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      chapters {
        title
        slug
        content
        description
        updatedAt(locale: "fr", formatString: "DD MMMM YYYY")
        time: updatedAt
      }
      level {
        title
        slug
      }
    }
  }
`
