import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from "./logo"

const Footer = props => {
  return (
    <footer className={'mt-10 md:mt-16 shadow-2xl'}>
      <div className="footer-top md:flex bg-teal-400">
        <div className="footer_left bg-white p-5 md:p-20 md:w-2/5">
          <Logo />
          <p className={'text-gray-600 py-4'}>
            Ce site a été conçu à l'origine par les élèves en classe de première et terminale du lycée Anna de Noailles à l’intention des élèves qui souhaitent retrouver les informations nécessaires aux révisions du cours de SES. Il ne constitue en aucun cas un ensemble suffisant pour passer les examens, mais simplement un résumé de quelques idées essentielles. Vos commentaires sont les bienvenus.
          </p>
        </div>
        <div className="footer-right flex flex-col self-center flex-1 p-5 text-white">
          <div className="footer-right-inner flex flex-row font-semibold md:pl-20 text-xl space-x-20">
            <ul>
              <li><Link to={'/'}>Accueil</Link></li>
              <li><Link to={'/cours/'}>Cours</Link></li>
              <li><Link to={'/mentions-legales'}>Mentions légales</Link></li>
            </ul>
            <ul>
              <li><Link to={'/ressources/'}>Ressources</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-red-400 h-12" />
    </footer>
  )
}

Footer.propTypes = {
  
}

export default Footer
